@import './normalize.css';
@import './variables.scss';
@import './mixins.scss';

body {
  height: 100%;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
}

a:hover {
  text-decoration: none
}
/*iconfont样式，通配icon-*/
[class*="ywb-icon-"] {
  font-family:"ywb-iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  div, ul, li {
    @include border-box;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div:focus{
  outline: none;
 }

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inline-block {
  display: block;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.theme-blue-linear {
  @include theme-blue-linear;
}

.theme-linear-button {
  border: 0;
  @include theme-blue-linear;
}

.text-ellipsis {
  @include text-ellipsis;
}

.smartfetch-system-message {
  z-index: 6000 !important;
}
p {
  margin: 0;
  padding: 0;
}