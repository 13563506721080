@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin relative {
  position: relative;
  width: 100%;
  height: 100%;
}
@mixin absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@mixin fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@mixin pct($pct) {
  width: #{$pct};
  position: relative;
  margin: 0 auto;
}

@mixin triangle($width, $height, $color, $direction) {
  $width: $width/2;
  $color-border-style: $height solid $color;
  $transparent-border-style: $width solid transparent;
  height: 0;
  width: 0;
  @if $direction==up {
    border-bottom: $color-border-style;
    border-left: $transparent-border-style;
    border-right: $transparent-border-style;
  }
  @else if $direction==right {
    border-left: $color-border-style;
    border-top: $transparent-border-style;
    border-bottom: $transparent-border-style;
  }
  @else if $direction==down {
    border-top: $color-border-style;
    border-left: $transparent-border-style;
    border-right: $transparent-border-style;
  }
  @else if $direction==left {
    border-right: $color-border-style;
    border-top: $transparent-border-style;
    border-bottom: $transparent-border-style;
  }
}
@mixin white-bg {
  background-color: $white;
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

@mixin shadow-card {
  @include white-bg;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}

@mixin border-box {
  box-sizing: border-box;
}

@mixin theme-blue-linear {
  background-image: linear-gradient(-90deg, #126efe 0%, #04c5ea 100%);
}